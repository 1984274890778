<template>
  <div>
    <full-page
      ref="fullpage"
      :options="options"
      id="fullpage"
      :style="fullStyle"
    >
      <div class="section home-bg">
        <swiper class="swiper_new" :options="swiperOption1">
          <swiper-slide v-for="(item, index) in swiper1" :key="index">
            <img :src="item.icon" class="advantage-swiper_img" />
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination1"></div>
        <div :style="displayStyle" class="home-logo" :class="home">
          <img src="../../static/images/home/logo.png" class="" />
        </div>
        <div :style="displayStyle" class="home-ban" :class="home">
          <span>品质生活·智慧和行</span>
        </div>
      </div>
      <div class="section">
        <div class="section-all">
          <div class="service-title">
            <span class="size36">我们的服务</span>
            <span>Service</span>
          </div>
          <div class="service">
            <div
              class="service-item service-item1"
              :class="service"
              @click="nat(1)"
            >
              <!-- <img src="./images/home/service-one.png" /> -->
              <div class="service-one"></div>
              <div class="service-font">
                <span class="size36">快车</span>
                <i></i>
                <p class="size16">安全合规 拒绝溢价 专职司机 统一车辆</p>
              </div>
            </div>
            <div
              class="service-item service-item2"
              :class="service"
              @click="nat(2)"
            >
              <div class="service-two"></div>
              <div class="service-font">
                <span class="size36">顺风车</span>
                <i></i>
                <p class="size16">免费合乘 公益互助 环保共创 快乐同行</p>
              </div>
              <!-- <img src="./images/home/service-two.png" /> -->
            </div>
            <div
              class="service-item service-item3"
              :class="service"
              @click="nat(3)"
            >
              <div class="service-three"></div>
              <div class="service-font">
                <span class="size36">企业用车</span>
                <i></i>
                <p class="size16">专业服务 节约成本 管理透明 提升效率</p>
              </div>
              <!-- <img src="./images/home/service-three.png" /> -->
            </div>
            <div
              class="service-item service-item4"
              :class="service"
              @click="nat(4)"
            >
              <div class="service-four"></div>
              <div class="service-font">
                <span class="size36">语音的士</span>
                <i></i>
                <p class="size16">语音叫车 打表计费 线下结算</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-all">
          <div class="advantage">
            <div class="advantage-title">
              <span class="size36">我们的优势</span>
              <span>Advantage</span>
            </div>
          </div>
          <div class="advantage-swiper">
            <swiper class="swiper" :class="swiperani" :options="swiperOption">
              <swiper-slide v-for="(item, index) in swiper" :key="index + 'o'">
                <div class="advantage-swiper__item">
                  <img :src="item.icon" class="advantage-swiper__img" />
                  <div class="advantage-swiper__right">
                    <span>{{ item.title }}</span>
                    <p>
                      {{ item.mome }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-container__nation">
              <div class="swiper-container-prev swiper-container__na">
                <img src="../../static/images/home/swiper-left.png" />
              </div>
              <div class="swiper-container-next swiper-container__na">
                <img src="../../static/images/home/swiper-right.png" />
              </div>
            </div>
          </div>
          <div class="footer-copy">
            <p>
              @2018-{{ time }} 合肥和行科技有限公司 All Rights Reserved {{
                time
              }} 和行约车.
            </p>
            <div class="bott">
              <img src="../../static/images/home/bottomlogo.png" />
              <p>皖公网安备 34019102000434号 皖ICP备18015830号-1.</p>
            </div>
          </div>
        </div>
      </div>
    </full-page>
  </div>
</template>
<script>
import "../../static/css/fullpage.min.css";
var vm = null;
export default {
  data() {
    let vm = this;
    return {
      displayStyle: { display: "none" },
      swiperOption1: {
        pagination: ".swiper-pagination1",
        paginationClickable: true,
        loop: true,
        speed: 1000,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        onTransitionStart: function (swiper) {
          if (swiper.activeIndex === 2) {
            vm.$set(vm.displayStyle, "display", "block");
          } else {
            vm.$set(vm.displayStyle, "display", "none");
          }
        },
      },
      swiper1: [
        {
          icon: require("../../static/images/home/home6.png"),
          index: 1,
        },
        {
          icon: require("../../static/images/home/bg.png"),
          index: 2,
        },
      ],
      options: {
        afterRender: this.afterRender,
        navigation: true,
        onLeave: this.onLeave,
      },
      home: "",
      service: "",
      swiper: [
        {
          title: "车企平台",
          icon: require("../../static/images/home/one-icon.png"),
          mome: "作为江汽集团的移动出行战略品牌，和行约车借助江汽集团全产业链竞争优势 ，创新性提供包括技术平台、运营车辆、维修保养、金融信贷、专业保险等在内的整体解决方案。和行约车承袭了江汽集团在长期生产经营过程中形成的严苛管理体系标准。",
        },
        {
          title: "品质服务",
          icon: require("../../static/images/home/two-icon.png"),
          mome: "车辆、司机统一标准、统筹管理，主打安全、优质、优价、专业的品质服务路线。根据市场容量和用户需求，设计多种满足市场需求的服务产品，以差异化经营给客户带来品质、便捷、超值的出行享受。",
        },
        {
          title: "技术赋能",
          icon: require("../../static/images/home/three-icon.png"),
          mome: "和行约车整合多方资源，借助互联网及大数据技术，对服务行程信息记录、留存，保证平台订单全程可追溯。警企联动，与公安机关深度合作，共同保障司乘安全。",
        },
        {
          title: "数据驱动",
          icon: require("../../static/images/home/four-icon.png"),
          mome: "引入广泛应用于互联网行业的成熟专业算法模型，配合和行约车的数据生态，和行约车APP优化用户端及驾驶员端的派单逻辑，更精准地进行派单决策。",
        },
      ],
      about: "",
      footer: "",
      swiperOption: {
        nextButton: ".swiper-container-next",
        prevButton: ".swiper-container-prev",
        loop: true,
      },
      swiperani: "",
      fullStyle: {},
      footerRight: "",
      time: "",
    };
  },
  mounted() {
    vm = this;
    this.routerTo(1);
    this.nowtime();
  },
  methods: {
    nat(index) {
      switch (index) {
        case 1:
          this.$router.push({ name: "pcexpress" });
          break;
        case 2:
          this.$router.push({ name: "pchitchRide" });
          break;
        case 3:
          this.$router.push({ name: "pcbusinessCar" });
          break;
        case 4:
          this.$router.push({ name: "pcvoiceTaxi" });
          break;
      }
    },
    afterRender() {
      this.home = "animate__animated animate__fadeInUp";
      this.fullStyle = {
        "z-index": "110",
      };
    },
    onLeave(e, n) {
      const { index } = n;
      switch (index) {
        case 0:
          this.fullStyle = {
            "z-index": "110",
          };
          break;
        case 1:
          this.service = "animate__animated animate__fadeInUp";
          this.fullStyle = {
            "z-index": "10",
          };
          break;
        case 2:
          this.swiperani = "animate__animated animate__fadeInUp";
          this.fullStyle = {
            "z-index": "10",
          };
          break;
        case 3:
          this.about = "animate__animated animate__fadeInUp";
          this.fullStyle = {
            "z-index": "10",
          };
          break;
        case 4:
          this.footer = "animate__animated animate__fadeInLeft";
          this.footerRight = "animate__animated animate__fadeInRight";
          this.fullStyle = {
            "z-index": "10",
          };
          break;
      }
    },
    nowtime() {
      let nowDate = new Date();
      let date = {
        // 获取当前年份
        year: nowDate.getFullYear(),
      };
      this.time = date.year;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../static/css/home.less");
@import url("../../static/css/media.less");

.bott {
  display: flex;

  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}
</style>